import React, { useImperativeHandle, useRef } from "react"
import "../styles/menu.scss"
import { Link } from "gatsby"

class Menu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }

  render() {
    return (
      <div className={`menu container ${this.state.open ? "open" : ""}`}>
        <div className="menu-movil-interior">
          {/* <div role="button" className="menu-cerrar" onClick={() => this.close()}>
                    <div className="cerrar"></div>
                </div> */}
          <ul className="enlaces-menu">
            <li>
              <Link to="/servicios/" onClick={() => this.close()}>
                Servicios
              </Link>
            </li>
            <li>
              <Link to="/proyectos/" onClick={() => this.close()}>
                Proyectos
              </Link>
            </li>
            <li>
              <Link to="/blog/" onClick={() => this.close()}>
                Blog
              </Link>
            </li>
          </ul>
          <ul className="enlaces-contacto">
            <li>
              <Link to="/contacto/" onClick={() => this.close()}>
                Contacto
              </Link>
            </li>
            {/*<li><Link to="/empleo" onClick={() => this.close()}>Empleo</Link></li>*/}
            <li>
              <Link to="/newsletter/" onClick={() => this.close()}>
                Newsletter
              </Link>
            </li>
            {/*<li><a href="https://bullhost.security" onClick={() => this.close()}>bullhost.security</a></li>*/}
          </ul>
        </div>
      </div>
    )
  }

  close() {
    this.setState({ open: false })
    document.body.style.overflow = "unset"
    document.getElementsByClassName("logo-primera")[0].style.fill = "#1f1f1f"
  }

  // open() {
  //   this.setState({ open: true })
  //   document.body.style.overflow = 'hidden';
  //   document.getElementsByClassName('logo-primera')[0].style.fill = '#fff'
  // }

  open() {
    this.setState(prevState => ({
      open: !prevState.open,
    }))

    if (!this.state.open) {
      document.body.style.overflow = "hidden"
      document.getElementsByClassName("logo-primera")[0].style.fill = "#fff"
    } else {
      document.body.style.overflow = "unset"
      document.getElementsByClassName("logo-primera")[0].style.fill = "#1f1f1f"
    }
  }
}

export default React.forwardRef((props, ref) => {
  const menuRef = useRef()

  useImperativeHandle(ref, () => ({
    open() {
      menuRef.current.open()
    },
    close() {
      menuRef.current.close()
    },
  }))

  return <Menu ref={menuRef} {...props} />
})
