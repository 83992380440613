import React from "react"
import Menu from "./menu"
import "../styles/header.scss"
import classnames from "classnames"
import { Link } from "gatsby"

const ventana = typeof window !== "undefined"

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      prevScrollpos: ventana.pageYOffset,
      visible: true,
      open: false,
    }
  }

  // Adds an event listener when the component is mount.
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  // Remove the event listener when the component is unmount.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  // Hide or show the menu.
  handleScroll = () => {
    const { prevScrollpos } = this.state

    const currentScrollPos = window.pageYOffset
    const visible = prevScrollpos > currentScrollPos

    this.setState({
      prevScrollpos: currentScrollPos,
      visible,
    })
  }

  render() {
    return (
      <>
        <nav
          className={classnames("barra-superior container", {
            "navbar--hidden": !this.state.visible,
          })}
        >
          <div>
            <Link to="/" className="logo" onClick={() => this.cerrarMenu()} title="Inicio" aria-label="Inicio">
              <svg
                width="100%"
                height="100%"
                version="1.1"
                viewBox="0 0 212 30.257"
              >
                <g transform="translate(115.83 -163.28)">
                  <g
                    className="logo-primera"
                    fill="#1f1f1f"
                    strokeWidth=".26458"
                  >
                    <path
                      className="st0"
                      d="m-100.92 178.93c0 3.7808-2.7463 6.7203-6.2362 6.7203-1.3573 0-2.5214-0.45243-3.3919-1.1959v0.96836l-4.7836-0.0318v-21.619l4.7836 0.0318v9.599c0.87312-0.74347 2.0373-1.1959 3.3919-1.1959 3.4898 3e-3 6.2362 2.9421 6.2362 6.723zm-4.781 0c0-1.4552-1.098-2.5532-2.5532-2.5532-1.4208 0-2.5532 1.098-2.5532 2.5532s1.1324 2.5532 2.5532 2.5532c1.4552 0 2.5532-1.098 2.5532-2.5532z"
                    />
                    <path
                      className="st0"
                      d="m-98.489 180.32v-7.8527h4.8154v7.2389c0 1.0028 0.71172 1.778 1.6483 1.778 0.96836 0 1.7118-0.77522 1.7118-1.778v-7.2389h4.7836v7.8527c0 3.2649-2.2304 5.3313-6.4954 5.3313-4.2333 3e-3 -6.4637-2.0664-6.4637-5.3313z"
                    />
                    <path
                      className="st0"
                      d="m-82.202 163.78h4.7836v21.619h-4.7836z"
                    />
                    <path
                      className="st0"
                      d="m-73.862 163.78h4.781v21.619h-4.7836v-21.619z"
                    />
                    <path
                      className="st0"
                      d="m-52.244 177.22v8.1755h-4.7836v-7.2707c0-0.93661-0.67732-1.7462-1.7118-1.7462-1.1642 0-2.0029 1.0345-2.0029 2.0373v6.9796h-4.7836v-21.619h4.7836v10.567c0.93661-1.3255 2.2939-2.1325 3.9105-2.1325 2.5849 0 4.5878 2.1325 4.5878 5.0085z"
                    />
                    <path
                      className="st0"
                      d="m-49.915 178.93c0-3.9105 2.8125-6.7203 7.3686-6.7203 4.5243 0 7.3342 2.8125 7.3342 6.7203 0 3.9105-2.8125 6.7203-7.3342 6.7203-4.5561 3e-3 -7.3686-2.8098-7.3686-6.7203zm9.9191 0c0-1.4869-1.0663-2.5214-2.5532-2.5532-1.5187 0.0318-2.5849 1.0663-2.5849 2.5532s1.0663 2.5214 2.5849 2.5532c1.4896-0.0318 2.5532-1.0663 2.5532-2.5532z"
                    />
                    <path
                      className="st0"
                      d="m-33.853 182.49 3.3602-1.7462c0.71172 1.1298 1.5504 1.2594 2.0373 1.2594 0.55033 0 0.87311-0.19315 0.87311-0.51593 0-0.29104-0.25929-0.58208-1.3255-0.96837-1.1642-0.45243-4.1698-1.2594-4.1698-4.0057 0-2.1643 1.8415-4.2968 5.1381-4.2968 1.8415 0 3.3919 0.64558 4.5243 1.5822l-2.1008 2.7463c-0.45243-0.42068-1.2277-0.90486-2.069-0.90486-0.61383 0-0.80697 0.25929-0.80697 0.48418 0 0.29104 0.35454 0.42068 1.0663 0.71172 1.8732 0.74347 4.1354 1.8097 4.1354 4.4926 0 2.4553-1.778 4.3312-4.9133 4.3312-2.3574-3e-3 -4.9106-1.0372-5.7493-3.1697z"
                    />
                    <path d="m-20.317 167.72v4.7862l0.0078 3.5186h-0.0052v4.3949c0 2.9421 2.5187 5.2358 5.4927 5.2358 0.93926 0 1.8755-0.1931 2.1983-0.42063v-3.7176h-1.5818c-0.80962 0-1.3255-0.64547-1.3255-1.68v-3.81h1.6851c0.06645 0 0.13112-6e-3 0.19482-0.016 5.1548-0.29879 3.6155-7.1167 3.6867-5.625 0.10848 2.2595-3.7438 2.0691-3.7438 2.0691l-0.0031 0.016h-1.8226v-4.7516h-4.7831z" />
                  </g>
                  <g className="logo-punto" fill="#777" strokeWidth=".26458">
                    <path
                      className="st3"
                      d="m-8.6619 183.22c0-1.36 1.1403-2.4685 2.4685-2.4685 1.3599 0 2.5003 1.1086 2.5003 2.4685 0 1.3282-1.1403 2.4685-2.5003 2.4685-1.3282 0-2.4685-1.1377-2.4685-2.4685z"
                    />
                  </g>
                  <g className="logo-segunda" fill="#777" strokeWidth=".26458">
                    <path
                      className="st3"
                      d="m15.039 181.64v3.7967c-0.28575 0.127-0.6641 0.254-1.0768 0.254-1.8044 0-3.3231-0.8546-4.2412-2.1828-0.91809 1.3599-2.3415 2.1828-4.1777 2.1828-3.4501 0-6.1383-2.8786-6.1383-6.5828 0-3.7015 2.6908-6.5828 6.1383-6.5828 1.3282 0 2.4368 0.44185 3.2914 1.1694v-0.91809h4.6831v7.7522c0 1.0133 0.56885 1.1086 1.0768 1.1086h0.44449zm-6.1383-1.5187 0.127-0.37835c0.063499-0.1905 0.095249-0.4101 0.095249-0.63235 0-1.4234-1.1403-2.5003-2.5003-2.5003-1.4234 0-2.532 1.0768-2.532 2.5003s1.1086 2.5003 2.532 2.5003c1.0107-3e-3 1.897-0.60324 2.278-1.4896z"
                    />
                    <path
                      className="st3"
                      d="m31.115 185.25c0 5.0323-2.6908 7.7839-7.6252 7.7839-3.4819 0-5.8552-1.3599-6.9611-3.9555l4.3338-1.6774c0.44185 1.0768 1.3599 1.6457 2.6273 1.6457 1.8997 0 2.9421-1.2329 2.9421-3.5427v-1.2964c-1.0133 0.9181-2.3415 1.4869-3.8602 1.4869-3.4501 0-6.1383-2.8786-6.1383-6.5828 0-3.7015 2.6908-6.5828 6.1383-6.5828 1.4869 0 2.7834 0.5371 3.7967 1.4552v-1.2012h4.7148zm-4.654-6.1383c0-1.4234-1.1086-2.5003-2.5003-2.5003-1.4234 0-2.532 1.0768-2.532 2.5003s1.1086 2.5003 2.532 2.5003c1.3943-3e-3 2.5003-1.0768 2.5003-2.5003z"
                    />
                    <path
                      className="st3"
                      d="m47.665 179.08v0.7911h-9.2391c0.127 1.6457 0.79109 2.532 2.4685 2.532 1.2012 0 1.9314-0.4736 2.151-1.2964h4.4608c-0.56885 2.7543-2.8786 4.5878-6.5828 4.5878-4.3973 0-7.2151-2.532-7.2151-6.5828 0-4.0825 2.8469-6.6145 7.0881-6.6145 4.2095 0 6.9002 2.532 6.8685 6.5828zm-8.9878-1.8044h4.273c-0.28575-0.8546-0.91809-1.3282-2.0558-1.3282-1.2356 0-1.8997 0.4736-2.2172 1.3282z"
                    />
                    <path
                      className="st3"
                      d="m63.264 177.43v8.0062h-4.6831v-7.0881c0-1.0133-0.69584-1.7409-1.7409-1.7409s-1.8997 0.7276-1.8997 1.7409v7.0881h-4.6831v-12.658h4.6831v1.8044c0.91809-1.2647 2.278-2.0558 3.8285-2.0558 2.532-3e-3 4.4952 2.0849 4.4952 4.9027z"
                    />
                    <path
                      className="st3"
                      d="m65.542 179.11c0-3.8285 2.7516-6.5828 7.2151-6.5828 4.0507 0 6.7071 2.3098 7.1198 5.6329h-4.7783c-0.34925-0.91809-1.2329-1.5187-2.3415-1.5504-1.4869 0.0317-2.532 1.0768-2.532 2.5003s1.0451 2.4685 2.532 2.5003c1.1086-0.0318 1.9923-0.63234 2.3415-1.5504h4.7783c-0.4101 3.3231-3.0691 5.6329-7.1198 5.6329-4.4635-3e-3 -7.2151-2.7543-7.2151-6.5828z"
                    />
                    <path
                      className="st3"
                      d="m95.673 172.78-6.0748 15.126-1.9923 5.1276h-4.9371l2.9739-7.2786-5.3154-12.975h4.8418l2.8786 7.7204 2.8469-7.7204z"
                    />
                  </g>
                </g>
              </svg>
            </Link>
          </div>
          <div
            role="button"
            className="menu-trigger"
            onClick={() => this.toggleMenu()}
            onKeyDown={() => this.toggleMenu()}
            title="Abrir/Cerrar Menú"
            aria-label="Abrir o cerrar Menú"
          >
            <div className={`hamburguesa ${this.state.open ? "open" : ""}`}>
              <div className="linea lin1"></div>
              <div className="linea lin2"></div>
              <div className="linea lin3"></div>
            </div>
          </div>
          <Menu ref={el => (this.childMenu = el)} />
        </nav>
      </>
    )
  }

  toggleMenu() {
    this.childMenu.open()
    this.setState(prevState => ({
      open: !prevState.open,
    }))
  }

  cerrarMenu() {
    this.setState({ open: false })
    this.childMenu.close()
  }
}

export default Header
